import React from "react"
import {Link} from "gatsby"
import { useLocation } from '@reach/router'


const Navbar = ()=>{
    const { pathname } = useLocation()
    console.log('Navbar location ',pathname)
    return(

      <div class="menu">
      <Link 
      to="/"
      activeStyle={{borderBottom: 'none'}}
      >
       <div class="menuitem">about</div>
      </Link>

      <Link 
      to="/services"
      activeStyle={{borderBottom: 'none'}}
      >
       <div class="menuitem">services</div>
      </Link>

      <Link 
      to="/projects"
      activeStyle={{borderBottom: 'none'}}
      >
       <div class="menuitem">projects</div>
      </Link>
    </div> 
    )
  }

export default Navbar;