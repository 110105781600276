import React from "react"
import "../styles.css"
import Navbar from "../components/Navbar"
import references from "../content/references.json"


export default function Home() {
  return (
<div>
    <Navbar></Navbar>
    <div class="content">
        <div class="contentbox">
            {references.left.map(references => (
            <div>
                <div class="service">
                    <div class="peter">{references.title}</div>
                    <div class="service-text">
                        <ul>{references.content.map(content => (
                       <li>{content.item}</li>
                        
                    ))}</ul></div>

                </div>
            </div>
            ))}
        </div>
        <div class="contentbox">
            {references.right.map(references => (      
            <div>
                <div class="service">
                    <div class="peter">{references.title}</div>
                    <div class="service-text">{references.content}</div>
                </div>
            </div>
            ))}
        </div>
    </div>
</div>
)}
